import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormInput,
  Alert,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getSingleForm = this.getSingleForm.bind(this);
    this.sendData = this.sendData.bind(this);
    this.alert = this.alert.bind(this);

    this.state = {
      status: false,
    };
  }

  async getSingleForm() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    let id = this.props.match.params.id;
    let id2 = this.props.match.params.id2;
    let response2 = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/singledata/${id}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    this.setState({
      // ipNo: response2.data.data.fields.hospital_ip_number_643,
      //name: response2.data.data.fields.name_149,
      formId: id,
      patient_id_793: id2
      // dateOfDisCharge: response2.data.data.fields.date_of_discharge_317
    });
    let type = this.props.match.params.no;

    if (type === "edit") {
      let response3 = await axios({
        method: "get",
        url: `${URL.BASE_URL}/forms/singlefollowdata/${id}`,

        headers: { Authorization: `Bearer ${token}` }
      });
      // console.log("🔥🚩🦚", response3.data);

      this.setState(response3.data.data.fields);
      this.setState({
        id: response3.data.data._id
      });
    }

    // console.log(this.state.fieldValue);
    // console.log(response2.data.data);
  }

  handleChange(e) {
    console.log(this.state);
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async sendData(e) {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let stateData = Object.assign({}, this.state);
    stateData.date_of_dead = `${stateData.date_of_dead_mm}-${stateData.date_of_dead_dd}-${stateData.date_of_dead_yyyy}`;

    //delete stateData.formId;
    delete stateData.name;
    delete stateData.ipNo;
    delete stateData.status;

    let followUpData = stateData; // this.state;
    // console.log(followUpData);
    // console.log("Sening");
    let type = this.props.match.params.no;

    let response;

    if (type === "edit") {
      response = await axios
        .patch(`${URL.BASE_URL}/forms/updatefollowupdata`, followUpData, config)
        .catch(function(error) {
          console.log(error);
          return;
        });
    } else {
      response = await axios
        .post(`${URL.BASE_URL}/forms/addfollowupdata`, followUpData, config)
        .catch(function(error) {
          console.log(error);
          return;
        });
    }

    if (response) {
      if (response.data.statusCode === 200) {
        this.setState({
          status: true,
          isCompleted: true
        });
      } else {
        this.setState({
          status: false
        });
      }
    }

    setTimeout(() => {
      this.setState({
        status: false
      });
    }, 2000);

    console.log(response);
  }

  customDate = ({ date, name }) => {
    let mm = date ? date.split("-")[0] : null;
    let dd = date ? date.split("-")[1] : null;
    let yyyy = date ? date.split("-")[2] : null;
    const dayArr = Array.from(Array(31).keys());
    const monthArr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const yearArr = [2018, 2019, 2020, 2021, 2022, 2023];
    const optionStyle = {
      padding: "5px",
      borderRadius: "3px",
      borderColor: "#ccc",
      color: "#aaa"
    };
    return (
      <>
        <select
          onChange={this.handleChange}
          style={optionStyle}
          id="date"
          name={`${name}_dd`}
        >
          <option value={dd ? dd : ""}>{dd ? dd : "DD"}</option>
          {dayArr.map(item => {
            return <option value={item + 1}>{item + 1}</option>;
          })}
        </select>
        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="month"
          name={`${name}_mm`}
        >
          <option value={mm ? mm : ""}>{mm ? monthArr[mm - 1] : "MM"}</option>

          {monthArr.map((item, key) => {
            return <option value={key + 1}>{item}</option>;
          })}
        </select>

        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="year"
          name={`${name}_yyyy`}
        >
          <option value={yyyy ? yyyy : ""}>{yyyy ? yyyy : "YYYY"}</option>

          {yearArr.map((item, key) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </>
    );
  };

  alert() {
    if (this.state.status) {
      return (
        <Alert className="mb-0">
          <i className="fa fa-info mx-2"></i> Successfully added fields
        </Alert>
      );
    } else {
      return <div></div>;
    }
  }

  monthDiff = () => {
    let d1;
    let d2;

    d2 = this.state.date_of_follow
      ? new Date(this.state.date_of_follow)
      : new Date();
    d1 = this.state.dateOfDisCharge
      ? new Date(this.state.dateOfDisCharge)
      : new Date();
    var diff = (d2.getTime() - d1.getTime()) / 1000;
    var diff2 = (d2.getTime() - d1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 30;
    diff2 /= 60 * 60 * 24;
    var day = Math.abs(Math.round(diff2) - 30 * Math.floor(diff));
    var month = Math.abs(Math.floor(diff));
    console.log("Month 🚀", diff);
    console.log("Day ✨", day);

    return { month: month, day: day };
  };

  AddNewField = () => {
    let alive = this.state.alive_dead;
    let typeOfFollowUp = this.state.type_of_follow_up;
    return (
      <>
        <this.alert />
        <Container fluid className="main-content-container px-4 pb-4">
          {/* Page Header */} <h2>30 Days Followup details</h2>
          <hr />
          <Row>
            <Col md="4">Type of Follow Up :</Col>
            <FormGroup>
              <input
                type="radio"
                id="radio20"
                name="type_of_follow_up"
                value="Hospital"
                checked={typeOfFollowUp === "Hospital"}
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Hospital
              <input
                type="radio"
                id="radio21"
                name="type_of_follow_up"
                value="Telephonic"
                onChange={this.handleChange}
                checked={typeOfFollowUp === "Telephonic"}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Telephonic
            </FormGroup>
          </Row>
          <hr />
          <Row>
            <Col md="4">Alive / Dead ? :</Col>
            <FormGroup>
              <input
                type="radio"
                id="radio20"
                name="alive_dead"
                value="Alive"
                onChange={this.handleChange}
                checked={alive === "Alive"}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Alive
              <input
                type="radio"
                id="radio21"
                name="alive_dead"
                checked={alive === "Dead"}
                value="Dead"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Dead
            </FormGroup>
          </Row>
          <hr />
          <this.firstCondition />
          <this.secondCondition />
          <Row>
            <Col>
              <Button
                size="sm"
                theme="success"
                className="mb-2 mr-1"
                onClick={this.sendData}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  firstCondition = () => {
    let alive = this.state.alive_dead;

    if (alive === "Alive") {
      return (
        <>
          <Row>
            <FormGroup>
              <Col md="12" sm="12">
                <FormGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText
                        style={{
                          color: "#99a",
                          "font-size": "14px",
                          "font-weight": "500"
                        }}
                      >
                        PR
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      type="number"
                      onChange={this.handleChange}
                      value={this.state.pr}
                      name="pr"
                    ></FormInput>
                  </InputGroup>
                </FormGroup>
              </Col>
            </FormGroup>

            <FormGroup>
              <Col md="12" sm="12">
                <FormGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText
                        style={{
                          color: "#99a",
                          "font-size": "14px",
                          "font-weight": "500"
                        }}
                      >
                        LVEF
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      type="number"
                      onChange={this.handleChange}
                      value={this.state.lvef}
                      name="lvef"
                    ></FormInput>
                  </InputGroup>
                </FormGroup>
              </Col>
            </FormGroup>
          </Row>{" "}
          <hr />
          <Row>
            <FormGroup>
              <Col md="12" sm="12">
                <FormGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText
                        style={{
                          color: "#99a",
                          "font-size": "14px",
                          "font-weight": "500"
                        }}
                      >
                        Systolic Pressure (mmHg)
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      type="number"
                      onChange={this.handleChange}
                      value={this.state.sbp}
                      name="sbp"
                    ></FormInput>
                  </InputGroup>
                </FormGroup>
              </Col>
            </FormGroup>

            <FormGroup>
              <Col md="12" sm="12">
                <FormGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText
                        style={{
                          color: "#99a",
                          "font-size": "14px",
                          "font-weight": "500"
                        }}
                      >
                        Diastolic Pressure (mmHg)
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      type="number"
                      onChange={this.handleChange}
                      value={this.state.bp}
                      name="bp"
                    ></FormInput>
                  </InputGroup>
                </FormGroup>
              </Col>
            </FormGroup>
          </Row>
          <hr />
          <h4>Medications ( Mention Dose )</h4>
          <Row>
            <Col md="6" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      Aspirin
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    onChange={this.handleChange}
                    name="aspirin"
                    value={this.state.aspirin}
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      Clopidogrel / Prasu / Tica
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    onChange={this.handleChange}
                    value={this.state.clopidogrel}
                    name="clopidogrel"
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      ACE (I)
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    onChange={this.handleChange}
                    name="ace"
                    value={this.state.ace}
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      ARB
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    onChange={this.handleChange}
                    value={this.state.arb}
                    name="arb"
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      Beta blockers
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    onChange={this.handleChange}
                    value={this.state.beta_blocker}
                    name="beta_blocker"
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      Ivabradine
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    onChange={this.handleChange}
                    name="ivabradine"
                    value={this.state.ivabradine}
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      Statins
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    onChange={this.handleChange}
                    name="statins"
                    value={this.state.statins}
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      MRA
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    onChange={this.handleChange}
                    name="mra"
                    value={this.state.mra}
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  };

  secondCondition = () => {
    let cause_of_death = this.state.cause_of_death;
    let alive = this.state.alive_dead;

    if (alive === "Dead") {
      return (
        <>
          <Row>
            <Col md="6">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      Specify
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="text"
                    onChange={this.handleChange}
                    value={this.state.cause_of_death}
                    name="cause_of_death"
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <FormGroup>
              <Col md="12" sm="12">
                <FormGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText
                        style={{
                          color: "#99a",
                          "font-size": "14px",
                          "font-weight": "500"
                        }}
                      >
                        Date of Death
                      </InputGroupText>
                    </InputGroupAddon>
                    <this.customDate
                      name="date_of_dead"
                      date={this.state.date_of_dead}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </FormGroup>
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  };

  componentDidMount() {
    this.getSingleForm();
  }

  render() {
    if (this.state.isCompleted) {
      return (
        <div>
          <Modal open={true}>
            <ModalHeader>Data Entered Successfully</ModalHeader>
            <ModalBody>
              <Link to="/dashbord">Goto Dashboard</Link>
            </ModalBody>
          </Modal>
        </div>
      );
    } else return this.AddNewField();
  }
}
