import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
  Alert,
  Card,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";

const FileDownload = require("js-file-download");

const Axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddUsers extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.InputFields = this.InputFields.bind(this);

    this.alert = this.alert.bind(this);

    this.state = {};
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.valueAsNumber
    });
    //console.log(this.state);
  };

  DownloadReport = async e => {
    let type = e.target.name;
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    Axios({
      url: `${URL.BASE_URL}/forms/reports?type=date&fromText=${this.state.fromText}&toText=${this.state.toText}`,
      method: "GET",
      responseType: "blob", // Important
      headers: { Authorization: `Bearer ${token}` }
    }).then(response => {
      FileDownload(
        response.data,
        `report_${this.state.fromText}_to_${this.state.fromText}.xlsx`
      );
    });
  };
  DownloadReportFollowUp = async e => {
    let type = e.target.name;
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    Axios({
      url: `${URL.BASE_URL}/fields/reports?type=date&fromText=${this.state.fromText}&toText=${this.state.toText}`,
      method: "GET",
      responseType: "blob", // Important
      headers: { Authorization: `Bearer ${token}` }
    }).then(response => {
      FileDownload(
        response.data,
        `report_${this.state.fromText}_to_${this.state.fromText}.xlsx`
      );
    });
  };
  alert() {
    if (this.state.status) {
      return (
        <Alert theme="success" className="mb-0">
          <i className="fa fa-info mx-2"></i> Successfully added fields
        </Alert>
      );
    } else if (this.state.error) {
      return (
        <Alert theme="danger" className="mb-0">
          <i className="fa fa-danger mx-2"></i> Error !! {this.state.errorCode}
        </Alert>
      );
    } else {
      return <div></div>;
    }
  }

  InputFieldsFollowUp = () => {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Form>
            <Row form>
              <Col md="12" className="form-group">
                <label htmlFor="feInputState">From Date </label>

                <input
                  type="date"
                  id="date"
                  name="fromText"
                  onChange={this.handleChange}
                  style={{
                    padding: "5px 5px",
                    margin: "8px 10px",
                    "box-sizing": "border-box",
                    color: "#ccc",
                    border: "1px solid #ccc",
                    "border-radius": "4px"
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md="12" className="form-group">
                <label htmlFor="feInputState">To Date </label>

                <input
                  type="date"
                  id="date2"
                  name="toText"
                  onChange={this.handleChange}
                  style={{
                    padding: "5px 5px",
                    margin: "8px 10px",
                    "box-sizing": "border-box",
                    color: "#ccc",
                    border: "1px solid #ccc",
                    "border-radius": "4px"
                  }}
                />
              </Col>
            </Row>

            <Button onClick={this.DownloadReportFollowUp}>Download</Button>
          </Form>
        </ListGroupItem>
      </ListGroup>
    );
  };
  InputFields() {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Form>
            <Row form>
              <Col md="12" className="form-group">
                <label htmlFor="feInputState">From Date </label>

                <input
                  type="date"
                  id="date"
                  name="fromText"
                  onChange={this.handleChange}
                  style={{
                    padding: "5px 5px",
                    margin: "8px 10px",
                    "box-sizing": "border-box",
                    color: "#ccc",
                    border: "1px solid #ccc",
                    "border-radius": "4px"
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md="12" className="form-group">
                <label htmlFor="feInputState">To Date </label>

                <input
                  type="date"
                  id="date2"
                  name="toText"
                  onChange={this.handleChange}
                  style={{
                    padding: "5px 5px",
                    margin: "8px 10px",
                    "box-sizing": "border-box",
                    color: "#ccc",
                    border: "1px solid #ccc",
                    "border-radius": "4px"
                  }}
                />
              </Col>
            </Row>

            <Button onClick={this.DownloadReport}>Download</Button>
          </Form>
        </ListGroupItem>
      </ListGroup>
    );
  }

  componentDidMount() {
    // this.getUserData();
  }

  AddNewField = () => {
    return (
      <>
        <this.alert />
        <Container fluid className="main-content-container px-4 pb-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle
                sm="4"
                title="Report"
                subtitle=""
                className="text-sm-left"
              />
            </Col>
            <Col>
              <PageTitle
                sm="4"
                title="Follow Up Report"
                subtitle=""
                className="text-sm-left"
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <this.InputFields />
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <this.InputFieldsFollowUp />
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  render() {
    return this.AddNewField();
  }
}
