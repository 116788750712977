import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// Route Views
import Dashbord from "./views/Dashbord";
import AddFormData from "./views/AddFormData";
import ListDatas from "./views/listFormData";
import FinishedData from "./views/FinishedData";
import AddUser from "./views/AddUser";
import ListUser from "./views/listUsers";
import UserData from "./views/UserData";
import AdminData from "./views/AdminData";
import Logout from "./views/Logout";
import Incomplet from "./views/listIncomp";
import FollowUpData from "./views/AddFollowUpData1";
import FollowUpEdit from "./views/followUpEdit";
import Report from "./views/Report";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashbord" />
  },
  {
    path: "/dashbord",
    layout: DefaultLayout,
    component: Dashbord
  },
  {
    path: "/add-formdata",
    layout: DefaultLayout,
    component: AddFormData
  },
  {
    path: "/list-formdata",
    layout: DefaultLayout,
    component: ListDatas
  },
  {
    path: "/incomplete",
    layout: DefaultLayout,
    component: Incomplet
  },
  {
    path: "/patientdata/:id",
    layout: DefaultLayout,
    component: AddFormData
  },
  {
    path: "/add-user",
    layout: DefaultLayout,
    component: AddUser
  },
  {
    path: "/finished",
    layout: DefaultLayout,
    component: FinishedData
  },
  {
    path: "/list-user",
    layout: DefaultLayout,
    component: ListUser
  },
  {
    path: "/userdata/:id",
    layout: DefaultLayout,
    component: UserData
  },
  {
    path: "/admin-profile",
    layout: DefaultLayout,
    component: AdminData
  },
  {
    path: "/add-followdata/:id/:id2/:no",
    layout: DefaultLayout,
    component: FollowUpData
  },
  {
    path: "/edit-followdata/:id/:id2/:no",
    layout: DefaultLayout,
    component: FollowUpEdit
  },
  {
    path: "/report",
    layout: DefaultLayout,
    component: Report
  },
  {
    path: "/logout",
    layout: LoginLayout,
    component: Logout
  },

  {
    path: "/login",
    layout: LoginLayout,
    component: () => <Redirect to="/dashbord" />
  }
];
