import React from "react";
import { Container, Button, Card, CardHeader, Row, Col } from "shards-react";
import LoginForm from "../components/clean/Login";

const Errors = () => (
  <Container fluid className="main-content-container  pt-4">
    <Row className="d-flex justify-content-center ">
      <Col md="5">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">Login</h6>
          </CardHeader>
          <LoginForm />
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Errors;
