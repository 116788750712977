import React, { Component } from "react";
import { Row, Col } from "shards-react";

import "./css/dashbordCard.css";

import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class dashbordCard extends Component {
  constructor(props) {
    super(props);
    this.getBasicSts = this.getBasicSts.bind(this);
    this.BasicCards = this.BasicCards.bind(this);
    this.HospCards = this.HospCards.bind(this);

    this.state = {
      isLoaded: false,
      basic_status: {
        comp: 0,
        incomp: 0,
        patient_count: 0,
        total_count: 0,
        hosp: 0
      }
    };
  }

  async getBasicSts() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response2 = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/basic`,

      headers: { Authorization: `Bearer ${token}` }
    });
    if (response2.data.statusCode === 200) {
      this.setState({ basic_status: response2.data.data });
    }

    console.log(response2.data.data);
    //console.log(response.data.data);
  }

  componentDidMount() {
    this.getBasicSts();
  }

  //For Admin, Super Admin
  BasicCards() {
    return (
      <div>
        <Row>
          <Col lg="6" md="6">
            <div className="cards card1">
              <div className="subtitle">Total Hospitals </div>
              <div className="title">{this.state.basic_status.hosp}</div>
            </div>
          </Col>
          <Col lg="6" md="6">
            <div className="cards card1">
              <div className="subtitle">Total Patients</div>
              <div className="title">{this.state.basic_status.total_count}</div>
            </div>
          </Col>
          {/* <Col lg="4" md="4">
            <div className="cards card1">
              <div className="subtitle">My Patients</div>
              <div className="title">
                {this.state.basic_status.patient_count}
              </div>
            </div>
    </Col>*/}
        </Row>
      </div>
    );
  }

  //For Co-Ordinator
  CoordinatorCard = () => {
    return (
      <div>
        <Row>
          <Col lg="4" md="4">
            <div className="cards card1">
              <div className="subtitle">Total Hospitals </div>
              <div className="title">{this.state.basic_status.hosp}</div>
            </div>
          </Col>
          <Col lg="4" md="4">
            <div className="cards card1">
              <div className="subtitle">Total Patients</div>
              <div className="title">{this.state.basic_status.total_count}</div>
            </div>
          </Col>
          <Col lg="4" md="4">
            <div className="cards card1">
              <div className="subtitle">Patients Under This Chapter</div>
              <div className="title">{this.state.basic_status.comp}</div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  //For Sub_coordinator
  HospCards() {
    return (
      <div>
        <Row>
          <Col lg="4" md="4">
            <div className="cards card1">
              <div className="subtitle">Total Hospitals </div>
              <div className="title">{this.state.basic_status.hosp}</div>
            </div>
          </Col>

          <Col lg="4" md="4">
            <div className="cards card1">
              <div className="subtitle">Total Patients</div>
              <div className="title">{this.state.basic_status.total_count}</div>
            </div>
          </Col>

          <Col lg="4" md="4">
            <div className="cards card1">
              <div className="subtitle">My Patient Data</div>
              <div className="title">
                {this.state.basic_status.patient_count}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    console.log("🚀💛🌟⭐🔥", this.props.userRole);
    if (this.props.userRole == "sub_coordinator") {
      return <this.HospCards />;
    } else if (this.props.userRole == "coordinator") {
      return <this.CoordinatorCard />;
    } else return <this.BasicCards />;
  }
}
