import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormSelect,
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Alert,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
import ReactLoading from "react-loading";
import { useMediaQuery } from "react-responsive";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.getFormFields = this.getFormFields.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.RenderFields = this.RenderFields.bind(this);
    this.firstSend = this.firstSend.bind(this);
    this.nextData = this.nextData.bind(this);
    this.prevData = this.prevData.bind(this);
    this.BottomButton = this.BottomButton.bind(this);
    this.TopTab = this.TopTab.bind(this);
    this.CustomRadio = this.CustomRadio.bind(this);
    this.CustomCheckBox = this.CustomCheckBox.bind(this);
    this.redirectState = this.redirectState.bind(this);
    this.LabParameterTable = this.LabParameterTable.bind(this);
    this.Validation = this.Validation.bind(this);
    this.MandatoryField = this.MandatoryField.bind(this);
    this.FieldValueCheck = this.FieldValueCheck.bind(this);
    this.state = {
      formFields: [],
      fieldValue: {},
      checkBoxValue: [],
      sliceArray: [],
      start: 0,
      end: 1,
      id: null,
      selectedFile: false,
      isLoading: false,
      validation: false,
      isCompleted: false
    };
  }

  async getFormFields() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .get(`${URL.BASE_URL}/fields/get`, config)
      .catch(error => {
        console.log(error);
        this.setState({
          error: error
        });
      });
    if (response) {
      let hospitalId = response.data.hospitalId;
      let count = Number(response.data.count) + 1;

      if (count < 10) {
        count = "000" + String(count);
      }
      if (count >= 10 && count < 100) {
        count = "00" + String(count);
      }
      if (count >= 100) {
        count = "0" + String(count);
      }

      let pId = hospitalId + String(count);

      this.setState({
        formFields: response.data.data,
        fieldValue: {
          patient_id_793: pId
        }
      });
      //console.log(response.data.data);

      let fields = [];
      let sliceNo = [];

      fields = response.data.data;

      fields.forEach((item, key) => {
        // console.log(key, item.fieldType);
        if (item.fieldType === "title") {
          sliceNo.push(key);
        }
      });
      sliceNo.push(fields.length);

      this.setState({
        sliceArray: sliceNo
      });

      //------

      let id = this.props.match.params.id;

      if (id) {
        let response2 = await axios({
          method: "get",
          url: `${URL.BASE_URL}/forms/singledata/${id}`,

          headers: { Authorization: `Bearer ${token}` }
        });

        this.setState({
          fieldValue: response2.data.data.fields,
          token: token,
          id: id
        });

        //console.log(this.state.fieldValue);
        //console.log(response.data.data);
      }
    }
  }

  handleChange(e) {
    var merged;
    let newItem;
    let oldItem = this.state.fieldValue;

    if (
      e.target.name == "date_of_admission_401" ||
      e.target.name == "date_of_discharge_death_421"
    ) {
      let date1;
      let date2;
      if (e.target.name == "date_of_admission_401") {
        date1 = new Date(e.target.value);
        date2 = new Date(this.state.fieldValue.date_of_discharge_death_421);
      } else {
        date1 = new Date(this.state.fieldValue.date_of_admission_401);
        date2 = new Date(e.target.value);
      }

      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      newItem = {
        [e.target.name]: e.target.value,
        days_of_hospitalization_566: diffDays
      };
    } else {
      newItem = { [e.target.name]: e.target.value };
    }

    merged = { ...oldItem, ...newItem };
    this.setState({
      fieldValue: merged
    });
  }

  customDate = ({ date, name }) => {
    let mm = date ? date.split("-")[0] : null;
    let dd = date ? date.split("-")[1] : null;
    let yyyy = date ? date.split("-")[2] : null;
    const dayArr = Array.from(Array(31).keys());
    const monthArr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const yearArr = [2018, 2019, 2020, 2021, 2022, 2023];
    const optionStyle = {
      padding: "5px",
      borderRadius: "3px",
      borderColor: "#ccc",
      color: "#aaa"
    };
    return (
      <>
        <select
          onChange={this.handleChange}
          style={optionStyle}
          id="date"
          name={`${name}_dd`}
        >
          <option value={dd ? dd : ""}>{dd ? dd : "DD"}</option>
          {dayArr.map(item => {
            return <option value={item + 1}>{item + 1}</option>;
          })}
        </select>
        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="month"
          name={`${name}_mm`}
        >
          <option value={mm ? mm : ""}>{mm ? monthArr[mm - 1] : "MM"}</option>

          {monthArr.map((item, key) => {
            return <option value={key + 1}>{item}</option>;
          })}
        </select>

        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="year"
          name={`${name}_yyyy`}
        >
          <option value={yyyy ? yyyy : ""}>{yyyy ? yyyy : "YYYY"}</option>

          {yearArr.map((item, key) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </>
    );
  };

  handleCheck(e) {
    let item = e.target.name;
    let value = e.target.value;
    let newItem = [];

    if (e.target.checked) {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.push(value);
    } else {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.pop(value);
    }

    this.setState({
      [item]: newItem
    });
  }

  nextData() {
    const valCheck = this.FieldValueCheck();
    //console.log("valcheck---->", valCheck);
    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );

    if (valCheck) {
      let oldStart = this.state.start;
      let oldEnd = this.state.end;
      this.setState({
        start: oldStart + 1,
        end: oldEnd + 1,
        showError: false
      });
      window.scrollTo(0, 0);
      this.firstSend(false);
    }
  }
  prevData() {
    let oldStart = this.state.start;
    let oldEnd = this.state.end;
    this.setState({
      start: oldStart - 1,
      end: oldEnd - 1
    });
    window.scrollTo(0, 0);
  }

  pagination(pageNo) {
    const valCheck = this.FieldValueCheck(true, pageNo);

    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );

    if (valCheck) {
      this.setState({
        start: pageNo - 1,
        end: pageNo,
        showError: false
      });
      window.scrollTo(0, 0);
      this.firstSend(false);
    }
  }

  Validation({ min, max, value, name }) {
    if (value < min || value > max) {
      return (
        <>
          <Alert
            theme="warning"
            style={{
              fontSize: "18px"
            }}
          >
            {name} - should be in between {min} to {max}
          </Alert>
        </>
      );
    } else {
      return <div></div>;
    }
  }

  MandatoryField({ value, value2, type, name, isMandatoryNot }) {
    let show = this.state.showError;

    if (type === "check_box") {
      let valueLen = 0;
      if (value2) {
        valueLen = value2.length;
      } else if (value) {
        valueLen = value.length;
      }
      if (show && !isMandatoryNot && valueLen === 0) {
        return (
          <>
            <Alert theme="danger">{name} - field is mandatory</Alert>
          </>
        );
      } else {
        return <div> </div>;
      }
    } else if (
      show &&
      !isMandatoryNot &&
      (value === undefined || value === "")
    ) {
      return (
        <>
          <Alert theme="danger">{name} - field is mandatory</Alert>
        </>
      );
    } else {
      return <div> </div>;
    }
  }

  FieldValueCheck(haveMultiPage, pageNo) {
    if (haveMultiPage && !this.props.match.params.id) {
      if (pageNo < this.state.end) {
        return true;
      }
      return false;
    }
    let fields = [];

    if (this.state.formFields) {
      fields = this.state.formFields;
    }
    let start = 0;
    let end = this.state.sliceArray[this.state.end];
    if (this.state.start > 0) {
      start = this.state.sliceArray[this.state.start];
    }

    let arrList = [];

    let sliceArr = fields.slice(start, end);

    sliceArr.map((field, key) => {
      //  console.log("✅❤💛💙🚀", Array.isArray(field.parentValue));
      let isCheckBox = false;
      let checkBoxLength = 0;
      if (field.fieldType === "check_box" || field.fieldType === "check_box2") {
        isCheckBox = true;
        if (this.state[field.fieldSlug]) {
          checkBoxLength = this.state[field.fieldSlug].length;
        } else if (this.state.fieldValue[field.fieldSlug]) {
          checkBoxLength = this.state.fieldValue[field.fieldSlug].length;
        }
      }

      let value = this.state.fieldValue[field.fieldSlug];

      if (field.parentValue) {
        if (
          !Array.isArray(field.parentValue) &&
          this.state.fieldValue[field.parentSlug[0]] == field.parentValue &&
          (value === undefined || value === "") &&
          (field.fieldType === "text" ||
            field.fieldType === "number" ||
            field.fieldType === "date" ||
            field.fieldType === "radio" ||
            field.fieldType === "check_box2" ||
            field.fieldType === "check_box" ||
            field.fieldType === "dropdown") &&
          !field.isMandatoryNot
        ) {
          if (isCheckBox) {
            if (checkBoxLength === 0) {
              arrList.push(false);
            } else {
              arrList.push(true);
            }
          } else {
            arrList.push(false);
          }
        } else if (
          Array.isArray(field.parentValue) &&
          this.state.fieldValue[field.parentSlug[0]] == field.parentValue[0] &&
          (value === undefined || value === "") &&
          (field.fieldType === "text" ||
            field.fieldType === "number" ||
            field.fieldType === "date" ||
            field.fieldType === "radio" ||
            field.fieldType === "check_box2" ||
            field.fieldType === "check_box" ||
            field.fieldType === "dropdown") &&
          !field.isMandatoryNot
        ) {
          if (isCheckBox) {
            if (checkBoxLength === 0) {
              arrList.push(false);
            } else {
              arrList.push(true);
            }
          } else {
            arrList.push(false);
          }
        } else {
          arrList.push(true);
        }
      } else if (
        !field.isMandatoryNot &&
        (value === undefined || value === "") &&
        field.parentValue === undefined &&
        (field.fieldType === "text" ||
          field.fieldType === "number" ||
          field.fieldType === "date" ||
          field.fieldType === "radio" ||
          field.fieldType === "check_box2" ||
          field.fieldType === "check_box" ||
          field.fieldType === "dropdown")
      ) {
        if (isCheckBox) {
          if (checkBoxLength === 0) {
            arrList.push(false);
          } else {
            arrList.push(true);
          }
        } else {
          arrList.push(false);
        }
      } else if (
        field.parentValue !== undefined &&
        (value !== undefined || value !== "") &&
        (field.fieldType === "text" ||
          field.fieldType === "number" ||
          field.fieldType === "date" ||
          field.fieldType === "check_box" ||
          field.fieldType === "check_box2" ||
          field.fieldType === "radio" ||
          field.fieldType === "dropdown")
      ) {
        if (isCheckBox) {
          if (checkBoxLength !== 0) {
            arrList.push(true);
          } else {
            arrList.push(false);
          }
        } else {
          arrList.push(true);
        }
      }
    });

    const validationCheck = arrList.every(value => value); // true for validation disabled

    if (!validationCheck) {
      this.setState({
        showError: true
      });
    }

    return validationCheck;
  }

  async firstSend(completed) {
    //e.preventDefault();

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let stateData = Object.assign({}, this.state);
    stateData.doa_302 = `${stateData.fieldValue.doa_302_mm}-${stateData.fieldValue.doa_302_dd}-${stateData.fieldValue.doa_302_yyyy}`;
    stateData.dod_674 = `${stateData.fieldValue.dod_674_mm}-${stateData.fieldValue.dod_674_dd}-${stateData.fieldValue.dod_674_yyyy}`;

    delete stateData.formFields;
    delete stateData.fieldValue;
    delete stateData.checkBoxValue;
    delete stateData.start;
    delete stateData.end;
    delete stateData.sliceArray;
    delete stateData.id;
    delete stateData.isCompleted;
    delete stateData.selectedFile;
    delete stateData.isLoading;
    delete stateData.showError;
    delete stateData.validation;

    let dataToSend = { ...this.state.fieldValue, ...stateData };

    let response;
    // if (this.state.start + 1 === this.state.sliceArray.length - 1) {
    //   dataToSend.isCompleted = true;
    //  }

    dataToSend.isCompleted = completed;

    if (this.state.id == null && this.state.start === 0) {
      response = await axios
        .post(`${URL.BASE_URL}/forms/adddata`, dataToSend, config)
        .catch(function(error) {
          console.log(error);
        });
      if (response.data.data) {
        this.setState({
          id: response.data.data._id
        });
      }
    } else if (this.state.id != null) {
      dataToSend.id = this.state.id;
      response = await axios
        .patch(`${URL.BASE_URL}/forms/updatedata`, dataToSend, config)
        .catch(function(error) {
          console.log(error);
        });
    }
  }

  redirectState() {
    const valCheck = this.FieldValueCheck();
    if (valCheck) {
      this.firstSend(true);
      this.setState({
        isCompleted: true
      });
    }
  }

  TopTab() {
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 700 });

    if (isDesktopOrLaptop) {
      return (
        <div>
          <nav>
            <ul className="pagination">
              {this.state.sliceArray.map((item, index) => {
                let titleIndex = this.state.sliceArray;
                if (index === this.state.start + 1) {
                  return (
                    <li className="page-item ">
                      <Button
                        size="sm"
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index} .
                        {this.state.formFields[titleIndex[index - 1]].fieldName}
                      </Button>
                    </li>
                  );
                } else if (index === 0) {
                  return <div></div>;
                } else {
                  return (
                    <li className="page-item">
                      <Button
                        size="sm"
                        outline
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index} .
                        {this.state.formFields[titleIndex[index - 1]].fieldName}
                      </Button>
                    </li>
                  );
                }
              })}
            </ul>
          </nav>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  BottomButton() {
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 700 });

    let start = this.state.start;
    let end = this.state.end;
    // console.log(this.state.sliceArray, start, end);
    let arrayLength = this.state.sliceArray.length;
    if (isDesktopOrLaptop) {
      if (start === 0) {
        return (
          <div>
            <nav>
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {/*   {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
                })} */}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1"
                    onClick={this.nextData}
                  >
                    Next
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else if (start === arrayLength - 2 && end === arrayLength - 1) {
        return (
          <div>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {/*
                {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
                })}
              */}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="success"
                    className="mb-2 mr-1"
                    onClick={this.redirectState}
                  >
                    Submit
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else if (start > 0 && end < arrayLength) {
        return (
          <div>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {/*
                {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
                })}*/}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1"
                    onClick={this.nextData}
                  >
                    Next
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else {
        return <div>" "</div>;
      }
    } else {
      if (start === 0) {
        return (
          <div>
            <nav>
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else if (index < 5) {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return <div></div>;
                  }
                })}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1"
                    onClick={this.nextData}
                  >
                    Next
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else if (start === arrayLength - 2 && end === arrayLength - 1) {
        return (
          <div>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else if (index > end - 4) {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return <div></div>;
                  }
                })}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="success"
                    className="mb-2 mr-1"
                    onClick={this.redirectState}
                  >
                    Submit
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else if (start > 0 && end < arrayLength) {
        return (
          <div>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else if (index < end + 3 && index > end - 3) {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return <div></div>;
                  }
                })}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1"
                    onClick={this.nextData}
                  >
                    Next
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else {
        return <div>" "</div>;
      }
    }
  }

  CustomRadio({ name, item }) {
    if (item === this.state.fieldValue[name]) {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              marginRight: "10px",
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
            defaultChecked
          ></input>
          {item}
        </>
      );
    } else {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              marginRight: "10px",

              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
          ></input>
          {item}
        </>
      );
    }
  }

  CustomCheckBox({ name, item }) {
    let arr = [];
    if (this.state[name]) {
      arr = this.state[name];
    } else if (this.state.fieldValue[name]) {
      arr = this.state.fieldValue[name];
    }
    // console.log(name, item);
    let isChecked = arr.includes(item);
    return (
      <>
        <Col md="12">
          <input
            type="checkbox"
            id="checkbox1"
            style={{
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
            defaultChecked={isChecked}
            name={name}
            value={item}
            onChange={this.handleCheck}
          />
          &nbsp; &nbsp; &nbsp;
          {item}
        </Col>
      </>
    );
  }

  LabParameterTable() {
    return (
      <div>
        <table className="table mb-0">
          <thead className="bg-light">
            <tr>
              <th scope="col" className="border-0">
                Parameter
              </th>
              <th scope="col" className="border-0">
                Test 1
              </th>
              <th scope="col" className="border-0">
                Test 2
              </th>
              <th scope="col" className="border-0">
                Test 3
              </th>
              <th scope="col" className="border-0">
                Test 4
              </th>
              <th scope="col" className="border-0">
                Test 5
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                <input
                  type="text"
                  name={"first_sample_111"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["first_sample_111"]}
                  placeholder="First Sample"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"hours_112"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["hours_112"]}
                  placeholder="Hours"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"hours_113"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["hours_113"]}
                  placeholder="Hours"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"hours_114"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["hours_114"]}
                  placeholder="Hours"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"hours_115"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["hours_115"]}
                  placeholder="Hours"
                />
              </td>
            </tr>
            <tr>
              <td>Lympocyte Count</td>
              <td>
                <input
                  type="text"
                  name={"value_112"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_112"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_113"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_113"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_114"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_114"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_115"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_115"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_116"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_116"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
            <tr>
              <td>CRP </td>
              <td>
                <input
                  type="text"
                  name={"value_122"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_122"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_123"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_123"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_124"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_124"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_125"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_125"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_126"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_126"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
            <tr>
              <td>D Dimer </td>
              <td>
                <input
                  type="text"
                  name={"value_131"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_131"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_132"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_132"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_133"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_133"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_134"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_134"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_135"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_135"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
            <tr>
              <td>Troponin </td>
              <td>
                <input
                  type="text"
                  name={"value_141"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_141"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_142"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_142"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_143"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_143"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_144"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_144"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_145"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_145"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
            <tr>
              <td>BNP/ NT proBNP</td>
              <td>
                <input
                  type="text"
                  name={"value_151"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_151"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_152"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_152"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_153"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_153"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_154"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_154"]}
                  placeholder="Enter"
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"value_155"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["value_155"]}
                  placeholder="Enter"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  RenderFields() {
    //For Second page other if condition
    let arr = [];

    var found;
    //   console.log(`%c --------------- `, "background: #dda; color: #ff0000");// color console
    //endd --------------------------------------
    let fields = [];

    if (this.state.formFields) {
      fields = this.state.formFields;
    }

    let start = 0;
    let end = this.state.sliceArray[this.state.end];
    if (this.state.start > 0) {
      start = this.state.sliceArray[this.state.start];
    }

    return (
      <div>
        {fields.slice(start, end).map((field, key) => {
          var parent_name;
          var parent_value = field.parentValue;
          var parent_curent_value;
          var parent_curent_value_array = [];
          var parent_name_array = [];
          var parent_value_array = [];

          let arr = [];
          var found2;

          if (this.state["risk_factorspast_history_yes_or_no_559"]) {
            found2 = this.state["risk_factorspast_history_yes_or_no_559"];
          } else if (
            this.state.fieldValue["risk_factorspast_history_yes_or_no_559"]
          ) {
            found2 = this.state.fieldValue[
              "risk_factorspast_history_yes_or_no_559"
            ];
          }

          if (this.state["if_yes_221"]) {
            arr = this.state["if_yes_221"];
          } else if (this.state.fieldValue["if_yes_221"]) {
            arr = this.state.fieldValue["if_yes_221"];
          }

          var found;

          if (arr) {
            found = arr.includes("Other");
          }

          let arr2 = [];
          if (this.state["aetiology_of_hf_886"]) {
            arr2 = this.state["aetiology_of_hf_886"];
          } else if (this.state.fieldValue["aetiology_of_hf_886"]) {
            arr2 = this.state.fieldValue["aetiology_of_hf_886"];
          }

          var found3;
          if (arr2) {
            found3 = arr2.includes("Others");
          }

          let colSize = { size: 6, offset: field.parentValue ? 1 : 0 };
          let radioColSize = { size: 4, offset: field.parentValue ? 1 : 0 };

          if (
            field.fieldSlug === "other_specify_176" &&
            found &&
            found2 === "Yes"
          ) {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />{" "}
                <Col md={colSize} sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}>
                            {" "}
                            {field.isMandatoryNot ? " " : "*"}
                          </strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="number"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                        min={field.minValue}
                        max={field.maxValue}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />{" "}
                </Col>
                <hr />
              </>
            );
          }

          if (field.fieldSlug === "if_other_457" && found3) {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />{" "}
                <Col md={colSize} sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}>
                            {" "}
                            {field.isMandatoryNot ? " " : "*"}
                          </strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="number"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                        min={field.minValue}
                        max={field.maxValue}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />{" "}
                </Col>
                <hr />
              </>
            );
          }

          if (field.parentSlug.length === 1) {
            parent_name = field.parentSlug[0];
            parent_curent_value = this.state.fieldValue[parent_name];
            //  console.log(parent_name);
            //  console.log(parent_value);
            //  console.log(parent_curent_value);
          }
          if (field.parentSlug.length > 1) {
            parent_name_array = field.parentSlug;
            parent_value_array = field.parentValue;
            parent_name_array.forEach(parent_item => {
              parent_curent_value_array.push(
                this.state.fieldValue[parent_item]
              );
            });
            {
              /*
            console.log("||||||||||||||||||||||||||||");
            console.log("length", field.parentSlug.length);
            console.log(parent_curent_value_array);
            console.log("array", parent_value_array);
            console.log(
              "Valuse",
              JSON.stringify(parent_value_array) ==
                JSON.stringify(parent_curent_value_array)
            );
            */
            }
          }

          //For Duration of hospital stay

          if (field.fieldSlug === "hospital_stay_309") {
            let duration =
              new Date(
                `${this.state.fieldValue.dod_674_mm}-${this.state.fieldValue.dod_674_dd}-${this.state.fieldValue.dod_674_yyyy}`
              ) -
              new Date(
                `${this.state.fieldValue.doa_302_mm}-${this.state.fieldValue.doa_302_dd}-${this.state.fieldValue.doa_302_yyyy}`
              );
            if (isNaN(duration)) duration = 0;
            const diffDays = Math.ceil(duration / (1000 * 60 * 60 * 24));

            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={diffDays}
                        disabled
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          }
          //END

          if (parent_name && parent_curent_value !== parent_value) {
            return (
              <div>
                {/*  "parent_name" : {parent_name} <br />
                "parent_value" : {parent_value} <br />
                "parent_current_value" : {parent_curent_value}
                */}
              </div>
            );
          } else if (
            field.parentSlug.length > 1 &&
            JSON.stringify(parent_value_array) !==
              JSON.stringify(parent_curent_value_array)
          ) {
            return (
              <div>
                {/* 
                "field Name" : {field.fieldName} <br />
                "Pos" : {field.pos}
                "Parent Count" : {field.parentSlug.length}
                 */}
              </div>
            );
          } else if (field.fieldSlug === "patient_id_793") {
            return (
              <>
                <Col lg="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue.patient_id_793}
                        disabled
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "text") {
            return (
              <>
                <Col lg="6" md={colSize} sm="12">
                  <this.MandatoryField
                    isMandatoryNot={field.isMandatoryNot}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}>
                            {field.isMandatoryNot ? " " : "*"}
                          </strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "number") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />{" "}
                <Col md={colSize} sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}>
                            {" "}
                            {field.isMandatoryNot ? " " : "*"}
                          </strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="number"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                        min={field.minValue}
                        max={field.maxValue}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />{" "}
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "radio") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />
                <Row>
                  <Col md={radioColSize}>
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                    :{" "}
                  </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          } else if (field.fieldType === "check_box") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  value2={this.state[field.fieldSlug]}
                  name={field.fieldName}
                  type="check_box"
                />
                <Row>
                  <Col
                    md={12}
                    style={{
                      fontSize: "18px",
                      marginBottom: "20px"
                    }}
                  >
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>
                  </Col>

                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <Col md="4">
                          <this.CustomCheckBox
                            name={field.fieldSlug}
                            item={item}
                          />
                        </Col>
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (field.fieldType === "dropdown") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />
                <Col md={colSize}>
                  <label htmlFor="feInputState">
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                  </label>
                  :{" "}
                  <FormSelect
                    id="feInputState"
                    name={field.fieldSlug}
                    onChange={this.handleChange}
                  >
                    <option>
                      {this.state.fieldValue[field.fieldSlug]
                        ? this.state.fieldValue[field.fieldSlug]
                        : "Select"}
                    </option>
                    {field.checkBoxEnum.map(item => {
                      return (
                        <>
                          <option>{item}</option>
                        </>
                      );
                    })}
                    <option value="">Select</option>
                  </FormSelect>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "date") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />

                <Col md={radioColSize}>
                  <label htmlFor="date">
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                  </label>
                  &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                  <this.customDate
                    name={field.fieldSlug}
                    date={this.state.fieldValue[field.fieldSlug]}
                  />
                </Col>

                <hr />
              </>
            );
          } else if (field.fieldType === "title") {
            return (
              <>
                <Col md="12">
                  <h2> {field.fieldName} </h2>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "sub_title") {
            return (
              <>
                <Col md={colSize}>
                  <h3>
                    {" "}
                    <strong> {field.fieldName} </strong>{" "}
                  </h3>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "note") {
            return (
              <>
                <Col md="12">
                  {" "}
                  <strong style={{ fontSize: "20px", fontWeight: "700" }}>
                    {" "}
                    {field.fieldName}{" "}
                  </strong>{" "}
                  <hr />
                </Col>
              </>
            );
          } else if (
            field.fieldType === "image" ||
            field.fieldType === "file"
          ) {
            return (
              <>
                {field.fieldName} &nbsp;&nbsp; : &nbsp;&nbsp;
                <hr />
              </>
            );
          } else if (field.fieldType === "table") {
            return <div></div>;
          } else if (field.fieldType === "document") {
            return <div></div>;
          } else if (field.fieldType === "check_box2") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                  type="check_box"
                  value2={this.state[field.fieldSlug]}
                />
                <Row>
                  <Col
                    md={12}
                    style={{
                      fontSize: "18px",
                      marginBottom: "20px"
                    }}
                  >
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}> *</strong>
                  </Col>

                  {field.checkBoxEnum.map((item, key) => {
                    let arr = [];
                    let name = field.fieldSlug;
                    if (this.state[name]) {
                      arr = this.state[name];
                    } else if (this.state.fieldValue[name]) {
                      arr = this.state.fieldValue[name];
                    }
                    // console.log(name, item);
                    let isChecked = arr.includes(item);
                    let disabled =
                      arr.includes("None") || arr.includes("Unknown");

                    if (disabled) {
                      if (arr.includes("None") && item === "None") {
                        disabled = false;
                      } else if (
                        arr.includes("Unknown") &&
                        item === "Unknown"
                      ) {
                        disabled = false;
                      }
                    }
                    return (
                      <>
                        <Col md="6">
                          <input
                            type="checkbox"
                            id="checkbox1"
                            style={{
                              fontSize: "16px",
                              border: "0px",
                              width: "20px",
                              height: "20px"
                            }}
                            defaultChecked={isChecked}
                            name={name}
                            value={item}
                            onChange={this.handleCheck}
                            disabled={disabled}
                          />
                          &nbsp; &nbsp; &nbsp;
                          {item}
                        </Col>
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (field.fieldType === "button") {
            return (
              <Button
                outline
                theme="dark"
                className="mb-2 mr-1"
                onClick={this.toggle}
              >
                For BARC Bleeding Criteria
              </Button>
            );
          }
          //return <li key={field.fieldSlug}>{field.fieldType}</li>;
        })}

        <this.BottomButton />
        <br />
        <strong>
          Steps : {this.state.start + 1} / {this.state.sliceArray.length - 1}
        </strong>
        <div>
          <Modal open={this.state.open} size="lg" toggle={this.toggle}>
            <ModalHeader>
              <Button
                theme="danger"
                className="mb-2 mr-1"
                onClick={this.toggle}
              >
                Close
              </Button>
            </ModalHeader>
            <ModalBody>
              <img
                src="https://httpsimage.com/v2/26a4c714-7171-430f-bfd1-5fdb53ba6878.png"
                alt="critiria"
                width="140%"
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}

        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Add Patients Data"
            subtitle=""
            className="text-sm-left"
          />
        </Row>
        <Row>
          <this.TopTab />
          <Col lg="12">
            <this.RenderFields />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getFormFields();
  }

  render() {
    if (this.state.isCompleted) {
      // return <Redirect to="../dashbord" />;
      return (
        <div>
          <Modal open={true}>
            <ModalHeader>Data Entered Successfully</ModalHeader>
            <ModalBody>
              <Link to="/dashbord">Goto Dashboard</Link>
            </ModalBody>
          </Modal>
        </div>
      );
    }
    if (this.state.sliceArray.length < 1) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else {
      return this.AddNewPost();
    }
  }
}
