import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Cookie from "js-cookie";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.state = {
      email: "",
      password: "",
      error: "",
      auth: false
    };
  }

  async login(e) {
    e.preventDefault();

    let response = await axios
      .post(`${URL.BASE_URL}/auth/signin`, {
        email: this.state.email,
        password: this.state.password
      })
      .catch(function(error) {
        console.log(error);
        this.setState({
          error: error
        });
      });

    if (response.data.statusCode === 200 && response.data.auth) {
      Cookie.set("jwtToken", response.data.token);
      Cookie.set("userData", response.data.data);
      this.setState({
        auth: true
      });
      console.log("-- Authenticated! --");
    } else if (response.data.statusCode === 404) {
      this.setState({
        error: response.data.msg
      });
    } else {
      this.setState({
        error: "Something went wrong !"
      });
    }

    console.log(this.state.error);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
    //console.log(this.state);
  }
  render() {
    if (this.state.auth) {
      window.location.reload(false);
      return <Redirect to="dashbord" />;
    }
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form className="d-flex justify-content-center ">
                  <Col md="10" className="form-group">
                    <label htmlFor="feEmailAddress">Email</label>
                    <FormInput
                      id="feEmailAddress"
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row form className="d-flex justify-content-center ">
                  <Col md="10">
                    <label htmlFor="fePassword">Password</label>
                    <FormInput
                      id="fePassword"
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row form className="d-flex justify-content-center ">
                  <Button type="submit" className="mt-4" onClick={this.login}>
                    Login
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row form className="d-flex justify-content-center ">
            <p
              style={{
                fontSize: "16px",
                marginTop: "20px",
                color: "#e74c3c",
                fontWeight: "500"
              }}
            >
              {this.state.error}
            </p>
          </Row>
        </ListGroupItem>
      </ListGroup>
    );
  }
}
