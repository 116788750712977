import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import DashbordCard from "../components/clean/dashbordCard";
import DashbordTable from "../components/clean/dashbordTable";
import PageTitle from "./../components/common/PageTitle";
import dashbordCard from "../components/clean/dashbordCard";

const Dashbord = ({ userRole }) => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4"></Row>
    {/* Small Stats Blocks */}
    <h4>ACS STEMI</h4>

    <DashbordCard userRole={userRole} />
    <DashbordTable userRole={userRole} />
  </Container>
);

export default Dashbord;
