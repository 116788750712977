export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashbord",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Add Fields",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/add-fields"
    },

    {
      title: "List Fields",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/listfields"
    },

    {
      title: "Add Patient data",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-formdata"
    },
    {
      title: "List Patient ",
      htmlBefore: '<i class="material-icons">healing</i>',
      to: "/list-formdata"
    },
    {
      title: "List incomplete  Patient ",
      htmlBefore: '<i class="material-icons">healing</i>',
      to: "/incomplete"
    },
    {
      title: "Finished Patient ",
      htmlBefore: '<i class="material-icons">healing</i>',
      to: "/finished"
    },
    {
      title: "Report ",
      htmlBefore: '<i class="material-icons">description</i>',
      to: "/report"
    },
    {
      title: "Add User ",
      htmlBefore: '<i class="material-icons">add_circle_outline</i>',
      to: "/add-user"
    },
    {
      title: "List User ",
      htmlBefore: '<i class="material-icons">account_circle</i>',
      to: "/list-user"
    },
    {
      title: "admin Profile ",
      htmlBefore: '<i class="material-icons">account_circle</i>',
      to: "/admin-profile"
    },
    {
      title: "Logout ",
      htmlBefore: '<i class="material-icons">&#xE879;</i>',
      to: "/logout"
    }
    /*
    {
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview"
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables"
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite"
    },
    {
      title: "Errors",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors"
    }
    */
  ];
}
