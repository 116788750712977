import React from "react";
import MainFooter from "../components/layout/MainFooter";
import { Container, Row, Col } from "shards-react";

const LoginLayout = ({ children }) => (
  <>
    <nav className="navbar navbar-dark  bg-primary">Inmenzo</nav>
    <Container fluid>
      {children}
      <div style={{ position: "fixed", width: "100%", bottom: 0 }}>
        <MainFooter />
      </div>
    </Container>
  </>
);

export default LoginLayout;
